/* stylelint-disable */
/* Teasergroup Startpage */
/* Teasergroup Startpage */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-no-hex */
/* stylelint-disable color-named */
/**
100 Thin
200 Extra Light
300 Light
400 Book
500 Medium
600 Bold
700 Black
800 Ultra Bold
 */
/* Fonts Variables */
.color--white {
  color: #ffffff; }
  .color--white svg {
    fill: #ffffff; }

.stripe-color--white {
  background: #ffffff;
  color: #ffffff; }

.stripe-headline-color--white {
  background: #ffffff;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #ffffff, -0.25rem 0 0 0.25rem #ffffff; }

.background-color--white {
  background: #ffffff;
  color: #ffffff; }
  .background-color--white svg {
    fill: #ffffff; }
  .background-color--white a.link-intern, .background-color--white a.link-external, .background-color--white a.link-download, .background-color--white a.link-email {
    color: #ffffff; }
    .background-color--white a.link-intern:hover, .background-color--white a.link-external:hover, .background-color--white a.link-download:hover, .background-color--white a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--white a.link-intern:hover, .background-color--white a.link-external:hover, .background-color--white a.link-download:hover, .background-color--white a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--white a.link-external, .background-color--white a.link-download {
    /* stylelint-disable */ }
    .background-color--white a.link-external span, .background-color--white a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--white a.link-external svg, .background-color--white a.link-download svg {
      fill: #ffffff; }

.color--black {
  color: #000000; }
  .color--black svg {
    fill: #000000; }

.stripe-color--black {
  background: #000000;
  color: #ffffff; }

.stripe-headline-color--black {
  background: #000000;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #000000, -0.25rem 0 0 0.25rem #000000; }

.background-color--black {
  background: #000000;
  color: #ffffff; }
  .background-color--black svg {
    fill: #ffffff; }
  .background-color--black a.link-intern, .background-color--black a.link-external, .background-color--black a.link-download, .background-color--black a.link-email {
    color: #ffffff; }
    .background-color--black a.link-intern:hover, .background-color--black a.link-external:hover, .background-color--black a.link-download:hover, .background-color--black a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--black a.link-intern:hover, .background-color--black a.link-external:hover, .background-color--black a.link-download:hover, .background-color--black a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--black a.link-external, .background-color--black a.link-download {
    /* stylelint-disable */ }
    .background-color--black a.link-external span, .background-color--black a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--black a.link-external svg, .background-color--black a.link-download svg {
      fill: #ffffff; }

.color--primary-fuchsia {
  color: #9e007e; }
  .color--primary-fuchsia svg {
    fill: #9e007e; }

.stripe-color--primary-fuchsia {
  background: #9e007e;
  color: #ffffff; }

.stripe-headline-color--primary-fuchsia {
  background: #9e007e;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #9e007e, -0.25rem 0 0 0.25rem #9e007e; }

.background-color--primary-fuchsia {
  background: #9e007e;
  color: #ffffff; }
  .background-color--primary-fuchsia svg {
    fill: #ffffff; }
  .background-color--primary-fuchsia a.link-intern, .background-color--primary-fuchsia a.link-external, .background-color--primary-fuchsia a.link-download, .background-color--primary-fuchsia a.link-email {
    color: #ffffff; }
    .background-color--primary-fuchsia a.link-intern:hover, .background-color--primary-fuchsia a.link-external:hover, .background-color--primary-fuchsia a.link-download:hover, .background-color--primary-fuchsia a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--primary-fuchsia a.link-intern:hover, .background-color--primary-fuchsia a.link-external:hover, .background-color--primary-fuchsia a.link-download:hover, .background-color--primary-fuchsia a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--primary-fuchsia a.link-external, .background-color--primary-fuchsia a.link-download {
    /* stylelint-disable */ }
    .background-color--primary-fuchsia a.link-external span, .background-color--primary-fuchsia a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--primary-fuchsia a.link-external svg, .background-color--primary-fuchsia a.link-download svg {
      fill: #ffffff; }

.color--primary-fuchsia-dark {
  color: #4f003f; }
  .color--primary-fuchsia-dark svg {
    fill: #4f003f; }

.stripe-color--primary-fuchsia-dark {
  background: #4f003f;
  color: #ffffff; }

.stripe-headline-color--primary-fuchsia-dark {
  background: #4f003f;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #4f003f, -0.25rem 0 0 0.25rem #4f003f; }

.background-color--primary-fuchsia-dark {
  background: #4f003f;
  color: #ffffff; }
  .background-color--primary-fuchsia-dark svg {
    fill: #ffffff; }
  .background-color--primary-fuchsia-dark a.link-intern, .background-color--primary-fuchsia-dark a.link-external, .background-color--primary-fuchsia-dark a.link-download, .background-color--primary-fuchsia-dark a.link-email {
    color: #ffffff; }
    .background-color--primary-fuchsia-dark a.link-intern:hover, .background-color--primary-fuchsia-dark a.link-external:hover, .background-color--primary-fuchsia-dark a.link-download:hover, .background-color--primary-fuchsia-dark a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--primary-fuchsia-dark a.link-intern:hover, .background-color--primary-fuchsia-dark a.link-external:hover, .background-color--primary-fuchsia-dark a.link-download:hover, .background-color--primary-fuchsia-dark a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--primary-fuchsia-dark a.link-external, .background-color--primary-fuchsia-dark a.link-download {
    /* stylelint-disable */ }
    .background-color--primary-fuchsia-dark a.link-external span, .background-color--primary-fuchsia-dark a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--primary-fuchsia-dark a.link-external svg, .background-color--primary-fuchsia-dark a.link-download svg {
      fill: #ffffff; }

.color--primary-fuchsia-bright {
  color: #e7bfdf; }
  .color--primary-fuchsia-bright svg {
    fill: #e7bfdf; }

.stripe-color--primary-fuchsia-bright {
  background: #e7bfdf;
  color: #ffffff; }

.stripe-headline-color--primary-fuchsia-bright {
  background: #e7bfdf;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #e7bfdf, -0.25rem 0 0 0.25rem #e7bfdf; }

.background-color--primary-fuchsia-bright {
  background: #e7bfdf;
  color: #ffffff; }
  .background-color--primary-fuchsia-bright svg {
    fill: #ffffff; }
  .background-color--primary-fuchsia-bright a.link-intern, .background-color--primary-fuchsia-bright a.link-external, .background-color--primary-fuchsia-bright a.link-download, .background-color--primary-fuchsia-bright a.link-email {
    color: #ffffff; }
    .background-color--primary-fuchsia-bright a.link-intern:hover, .background-color--primary-fuchsia-bright a.link-external:hover, .background-color--primary-fuchsia-bright a.link-download:hover, .background-color--primary-fuchsia-bright a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--primary-fuchsia-bright a.link-intern:hover, .background-color--primary-fuchsia-bright a.link-external:hover, .background-color--primary-fuchsia-bright a.link-download:hover, .background-color--primary-fuchsia-bright a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--primary-fuchsia-bright a.link-external, .background-color--primary-fuchsia-bright a.link-download {
    /* stylelint-disable */ }
    .background-color--primary-fuchsia-bright a.link-external span, .background-color--primary-fuchsia-bright a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--primary-fuchsia-bright a.link-external svg, .background-color--primary-fuchsia-bright a.link-download svg {
      fill: #ffffff; }

.color--primary-cyan {
  color: #00a9e0; }
  .color--primary-cyan svg {
    fill: #00a9e0; }

.stripe-color--primary-cyan {
  background: #00a9e0;
  color: #ffffff; }

.stripe-headline-color--primary-cyan {
  background: #00a9e0;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #00a9e0, -0.25rem 0 0 0.25rem #00a9e0; }

.background-color--primary-cyan {
  background: #00a9e0;
  color: #ffffff; }
  .background-color--primary-cyan svg {
    fill: #ffffff; }
  .background-color--primary-cyan a.link-intern, .background-color--primary-cyan a.link-external, .background-color--primary-cyan a.link-download, .background-color--primary-cyan a.link-email {
    color: #ffffff; }
    .background-color--primary-cyan a.link-intern:hover, .background-color--primary-cyan a.link-external:hover, .background-color--primary-cyan a.link-download:hover, .background-color--primary-cyan a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--primary-cyan a.link-intern:hover, .background-color--primary-cyan a.link-external:hover, .background-color--primary-cyan a.link-download:hover, .background-color--primary-cyan a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--primary-cyan a.link-external, .background-color--primary-cyan a.link-download {
    /* stylelint-disable */ }
    .background-color--primary-cyan a.link-external span, .background-color--primary-cyan a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--primary-cyan a.link-external svg, .background-color--primary-cyan a.link-download svg {
      fill: #ffffff; }

.color--secondary-violet {
  color: #5c2483; }
  .color--secondary-violet svg {
    fill: #5c2483; }

.stripe-color--secondary-violet {
  background: #5c2483;
  color: #ffffff; }

.stripe-headline-color--secondary-violet {
  background: #5c2483;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #5c2483, -0.25rem 0 0 0.25rem #5c2483; }

.background-color--secondary-violet {
  background: #5c2483;
  color: #ffffff; }
  .background-color--secondary-violet svg {
    fill: #ffffff; }
  .background-color--secondary-violet a.link-intern, .background-color--secondary-violet a.link-external, .background-color--secondary-violet a.link-download, .background-color--secondary-violet a.link-email {
    color: #ffffff; }
    .background-color--secondary-violet a.link-intern:hover, .background-color--secondary-violet a.link-external:hover, .background-color--secondary-violet a.link-download:hover, .background-color--secondary-violet a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-violet a.link-intern:hover, .background-color--secondary-violet a.link-external:hover, .background-color--secondary-violet a.link-download:hover, .background-color--secondary-violet a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-violet a.link-external, .background-color--secondary-violet a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-violet a.link-external span, .background-color--secondary-violet a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-violet a.link-external svg, .background-color--secondary-violet a.link-download svg {
      fill: #ffffff; }

.color--secondary-red {
  color: #e40046; }
  .color--secondary-red svg {
    fill: #e40046; }

.stripe-color--secondary-red {
  background: #e40046;
  color: #ffffff; }

.stripe-headline-color--secondary-red {
  background: #e40046;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #e40046, -0.25rem 0 0 0.25rem #e40046; }

.background-color--secondary-red {
  background: #e40046;
  color: #ffffff; }
  .background-color--secondary-red svg {
    fill: #ffffff; }
  .background-color--secondary-red a.link-intern, .background-color--secondary-red a.link-external, .background-color--secondary-red a.link-download, .background-color--secondary-red a.link-email {
    color: #ffffff; }
    .background-color--secondary-red a.link-intern:hover, .background-color--secondary-red a.link-external:hover, .background-color--secondary-red a.link-download:hover, .background-color--secondary-red a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-red a.link-intern:hover, .background-color--secondary-red a.link-external:hover, .background-color--secondary-red a.link-download:hover, .background-color--secondary-red a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-red a.link-external, .background-color--secondary-red a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-red a.link-external span, .background-color--secondary-red a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-red a.link-external svg, .background-color--secondary-red a.link-download svg {
      fill: #ffffff; }

.color--secondary-orange {
  color: #ff9e1b; }
  .color--secondary-orange svg {
    fill: #ff9e1b; }

.stripe-color--secondary-orange {
  background: #ff9e1b;
  color: #ffffff; }

.stripe-headline-color--secondary-orange {
  background: #ff9e1b;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #ff9e1b, -0.25rem 0 0 0.25rem #ff9e1b; }

.background-color--secondary-orange {
  background: #ff9e1b;
  color: #ffffff; }
  .background-color--secondary-orange svg {
    fill: #ffffff; }
  .background-color--secondary-orange a.link-intern, .background-color--secondary-orange a.link-external, .background-color--secondary-orange a.link-download, .background-color--secondary-orange a.link-email {
    color: #ffffff; }
    .background-color--secondary-orange a.link-intern:hover, .background-color--secondary-orange a.link-external:hover, .background-color--secondary-orange a.link-download:hover, .background-color--secondary-orange a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-orange a.link-intern:hover, .background-color--secondary-orange a.link-external:hover, .background-color--secondary-orange a.link-download:hover, .background-color--secondary-orange a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-orange a.link-external, .background-color--secondary-orange a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-orange a.link-external span, .background-color--secondary-orange a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-orange a.link-external svg, .background-color--secondary-orange a.link-download svg {
      fill: #ffffff; }

.color--secondary-yellow {
  color: #ffd100; }
  .color--secondary-yellow svg {
    fill: #ffd100; }

.stripe-color--secondary-yellow {
  background: #ffd100;
  color: #ffffff; }

.stripe-headline-color--secondary-yellow {
  background: #ffd100;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #ffd100, -0.25rem 0 0 0.25rem #ffd100; }

.background-color--secondary-yellow {
  background: #ffd100;
  color: #ffffff; }
  .background-color--secondary-yellow svg {
    fill: #ffffff; }
  .background-color--secondary-yellow a.link-intern, .background-color--secondary-yellow a.link-external, .background-color--secondary-yellow a.link-download, .background-color--secondary-yellow a.link-email {
    color: #ffffff; }
    .background-color--secondary-yellow a.link-intern:hover, .background-color--secondary-yellow a.link-external:hover, .background-color--secondary-yellow a.link-download:hover, .background-color--secondary-yellow a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-yellow a.link-intern:hover, .background-color--secondary-yellow a.link-external:hover, .background-color--secondary-yellow a.link-download:hover, .background-color--secondary-yellow a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-yellow a.link-external, .background-color--secondary-yellow a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-yellow a.link-external span, .background-color--secondary-yellow a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-yellow a.link-external svg, .background-color--secondary-yellow a.link-download svg {
      fill: #ffffff; }

.color--secondary-light-green {
  color: #93c90e; }
  .color--secondary-light-green svg {
    fill: #93c90e; }

.stripe-color--secondary-light-green {
  background: #93c90e;
  color: #ffffff; }

.stripe-headline-color--secondary-light-green {
  background: #93c90e;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #93c90e, -0.25rem 0 0 0.25rem #93c90e; }

.background-color--secondary-light-green {
  background: #93c90e;
  color: #ffffff; }
  .background-color--secondary-light-green svg {
    fill: #ffffff; }
  .background-color--secondary-light-green a.link-intern, .background-color--secondary-light-green a.link-external, .background-color--secondary-light-green a.link-download, .background-color--secondary-light-green a.link-email {
    color: #ffffff; }
    .background-color--secondary-light-green a.link-intern:hover, .background-color--secondary-light-green a.link-external:hover, .background-color--secondary-light-green a.link-download:hover, .background-color--secondary-light-green a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-light-green a.link-intern:hover, .background-color--secondary-light-green a.link-external:hover, .background-color--secondary-light-green a.link-download:hover, .background-color--secondary-light-green a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-light-green a.link-external, .background-color--secondary-light-green a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-light-green a.link-external span, .background-color--secondary-light-green a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-light-green a.link-external svg, .background-color--secondary-light-green a.link-download svg {
      fill: #ffffff; }

.color--secondary-dark-green {
  color: #00894c; }
  .color--secondary-dark-green svg {
    fill: #00894c; }

.stripe-color--secondary-dark-green {
  background: #00894c;
  color: #ffffff; }

.stripe-headline-color--secondary-dark-green {
  background: #00894c;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #00894c, -0.25rem 0 0 0.25rem #00894c; }

.background-color--secondary-dark-green {
  background: #00894c;
  color: #ffffff; }
  .background-color--secondary-dark-green svg {
    fill: #ffffff; }
  .background-color--secondary-dark-green a.link-intern, .background-color--secondary-dark-green a.link-external, .background-color--secondary-dark-green a.link-download, .background-color--secondary-dark-green a.link-email {
    color: #ffffff; }
    .background-color--secondary-dark-green a.link-intern:hover, .background-color--secondary-dark-green a.link-external:hover, .background-color--secondary-dark-green a.link-download:hover, .background-color--secondary-dark-green a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-dark-green a.link-intern:hover, .background-color--secondary-dark-green a.link-external:hover, .background-color--secondary-dark-green a.link-download:hover, .background-color--secondary-dark-green a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-dark-green a.link-external, .background-color--secondary-dark-green a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-dark-green a.link-external span, .background-color--secondary-dark-green a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-dark-green a.link-external svg, .background-color--secondary-dark-green a.link-download svg {
      fill: #ffffff; }

.color--secondary-petrol {
  color: #00b097; }
  .color--secondary-petrol svg {
    fill: #00b097; }

.stripe-color--secondary-petrol {
  background: #00b097;
  color: #ffffff; }

.stripe-headline-color--secondary-petrol {
  background: #00b097;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #00b097, -0.25rem 0 0 0.25rem #00b097; }

.background-color--secondary-petrol {
  background: #00b097;
  color: #ffffff; }
  .background-color--secondary-petrol svg {
    fill: #ffffff; }
  .background-color--secondary-petrol a.link-intern, .background-color--secondary-petrol a.link-external, .background-color--secondary-petrol a.link-download, .background-color--secondary-petrol a.link-email {
    color: #ffffff; }
    .background-color--secondary-petrol a.link-intern:hover, .background-color--secondary-petrol a.link-external:hover, .background-color--secondary-petrol a.link-download:hover, .background-color--secondary-petrol a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-petrol a.link-intern:hover, .background-color--secondary-petrol a.link-external:hover, .background-color--secondary-petrol a.link-download:hover, .background-color--secondary-petrol a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-petrol a.link-external, .background-color--secondary-petrol a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-petrol a.link-external span, .background-color--secondary-petrol a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-petrol a.link-external svg, .background-color--secondary-petrol a.link-download svg {
      fill: #ffffff; }

.color--secondary-dark-blue {
  color: #2268b1; }
  .color--secondary-dark-blue svg {
    fill: #2268b1; }

.stripe-color--secondary-dark-blue {
  background: #2268b1;
  color: #ffffff; }

.stripe-headline-color--secondary-dark-blue {
  background: #2268b1;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #2268b1, -0.25rem 0 0 0.25rem #2268b1; }

.background-color--secondary-dark-blue {
  background: #2268b1;
  color: #ffffff; }
  .background-color--secondary-dark-blue svg {
    fill: #ffffff; }
  .background-color--secondary-dark-blue a.link-intern, .background-color--secondary-dark-blue a.link-external, .background-color--secondary-dark-blue a.link-download, .background-color--secondary-dark-blue a.link-email {
    color: #ffffff; }
    .background-color--secondary-dark-blue a.link-intern:hover, .background-color--secondary-dark-blue a.link-external:hover, .background-color--secondary-dark-blue a.link-download:hover, .background-color--secondary-dark-blue a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-dark-blue a.link-intern:hover, .background-color--secondary-dark-blue a.link-external:hover, .background-color--secondary-dark-blue a.link-download:hover, .background-color--secondary-dark-blue a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-dark-blue a.link-external, .background-color--secondary-dark-blue a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-dark-blue a.link-external span, .background-color--secondary-dark-blue a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-dark-blue a.link-external svg, .background-color--secondary-dark-blue a.link-download svg {
      fill: #ffffff; }

.color--secondary-dark-blue-20 {
  color: #bfe9f7; }
  .color--secondary-dark-blue-20 svg {
    fill: #bfe9f7; }

.stripe-color--secondary-dark-blue-20 {
  background: #bfe9f7;
  color: #ffffff; }

.stripe-headline-color--secondary-dark-blue-20 {
  background: #bfe9f7;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #bfe9f7, -0.25rem 0 0 0.25rem #bfe9f7; }

.background-color--secondary-dark-blue-20 {
  background: #bfe9f7;
  color: #ffffff; }
  .background-color--secondary-dark-blue-20 svg {
    fill: #ffffff; }
  .background-color--secondary-dark-blue-20 a.link-intern, .background-color--secondary-dark-blue-20 a.link-external, .background-color--secondary-dark-blue-20 a.link-download, .background-color--secondary-dark-blue-20 a.link-email {
    color: #ffffff; }
    .background-color--secondary-dark-blue-20 a.link-intern:hover, .background-color--secondary-dark-blue-20 a.link-external:hover, .background-color--secondary-dark-blue-20 a.link-download:hover, .background-color--secondary-dark-blue-20 a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--secondary-dark-blue-20 a.link-intern:hover, .background-color--secondary-dark-blue-20 a.link-external:hover, .background-color--secondary-dark-blue-20 a.link-download:hover, .background-color--secondary-dark-blue-20 a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--secondary-dark-blue-20 a.link-external, .background-color--secondary-dark-blue-20 a.link-download {
    /* stylelint-disable */ }
    .background-color--secondary-dark-blue-20 a.link-external span, .background-color--secondary-dark-blue-20 a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--secondary-dark-blue-20 a.link-external svg, .background-color--secondary-dark-blue-20 a.link-download svg {
      fill: #ffffff; }

.color--neutral-grey-0 {
  color: #f6f6f6; }
  .color--neutral-grey-0 svg {
    fill: #f6f6f6; }

.stripe-color--neutral-grey-0 {
  background: #f6f6f6;
  color: #ffffff; }

.stripe-headline-color--neutral-grey-0 {
  background: #f6f6f6;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #f6f6f6, -0.25rem 0 0 0.25rem #f6f6f6; }

.background-color--neutral-grey-0 {
  background: #f6f6f6;
  color: #ffffff; }
  .background-color--neutral-grey-0 svg {
    fill: #ffffff; }
  .background-color--neutral-grey-0 a.link-intern, .background-color--neutral-grey-0 a.link-external, .background-color--neutral-grey-0 a.link-download, .background-color--neutral-grey-0 a.link-email {
    color: #ffffff; }
    .background-color--neutral-grey-0 a.link-intern:hover, .background-color--neutral-grey-0 a.link-external:hover, .background-color--neutral-grey-0 a.link-download:hover, .background-color--neutral-grey-0 a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--neutral-grey-0 a.link-intern:hover, .background-color--neutral-grey-0 a.link-external:hover, .background-color--neutral-grey-0 a.link-download:hover, .background-color--neutral-grey-0 a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--neutral-grey-0 a.link-external, .background-color--neutral-grey-0 a.link-download {
    /* stylelint-disable */ }
    .background-color--neutral-grey-0 a.link-external span, .background-color--neutral-grey-0 a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--neutral-grey-0 a.link-external svg, .background-color--neutral-grey-0 a.link-download svg {
      fill: #ffffff; }

.color--neutral-grey-1 {
  color: #d9d9d6; }
  .color--neutral-grey-1 svg {
    fill: #d9d9d6; }

.stripe-color--neutral-grey-1 {
  background: #d9d9d6;
  color: #ffffff; }

.stripe-headline-color--neutral-grey-1 {
  background: #d9d9d6;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #d9d9d6, -0.25rem 0 0 0.25rem #d9d9d6; }

.background-color--neutral-grey-1 {
  background: #d9d9d6;
  color: #ffffff; }
  .background-color--neutral-grey-1 svg {
    fill: #ffffff; }
  .background-color--neutral-grey-1 a.link-intern, .background-color--neutral-grey-1 a.link-external, .background-color--neutral-grey-1 a.link-download, .background-color--neutral-grey-1 a.link-email {
    color: #ffffff; }
    .background-color--neutral-grey-1 a.link-intern:hover, .background-color--neutral-grey-1 a.link-external:hover, .background-color--neutral-grey-1 a.link-download:hover, .background-color--neutral-grey-1 a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--neutral-grey-1 a.link-intern:hover, .background-color--neutral-grey-1 a.link-external:hover, .background-color--neutral-grey-1 a.link-download:hover, .background-color--neutral-grey-1 a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--neutral-grey-1 a.link-external, .background-color--neutral-grey-1 a.link-download {
    /* stylelint-disable */ }
    .background-color--neutral-grey-1 a.link-external span, .background-color--neutral-grey-1 a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--neutral-grey-1 a.link-external svg, .background-color--neutral-grey-1 a.link-download svg {
      fill: #ffffff; }

.color--neutral-grey-2 {
  color: #c8c9c7; }
  .color--neutral-grey-2 svg {
    fill: #c8c9c7; }

.stripe-color--neutral-grey-2 {
  background: #c8c9c7;
  color: #ffffff; }

.stripe-headline-color--neutral-grey-2 {
  background: #c8c9c7;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #c8c9c7, -0.25rem 0 0 0.25rem #c8c9c7; }

.background-color--neutral-grey-2 {
  background: #c8c9c7;
  color: #ffffff; }
  .background-color--neutral-grey-2 svg {
    fill: #ffffff; }
  .background-color--neutral-grey-2 a.link-intern, .background-color--neutral-grey-2 a.link-external, .background-color--neutral-grey-2 a.link-download, .background-color--neutral-grey-2 a.link-email {
    color: #ffffff; }
    .background-color--neutral-grey-2 a.link-intern:hover, .background-color--neutral-grey-2 a.link-external:hover, .background-color--neutral-grey-2 a.link-download:hover, .background-color--neutral-grey-2 a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--neutral-grey-2 a.link-intern:hover, .background-color--neutral-grey-2 a.link-external:hover, .background-color--neutral-grey-2 a.link-download:hover, .background-color--neutral-grey-2 a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--neutral-grey-2 a.link-external, .background-color--neutral-grey-2 a.link-download {
    /* stylelint-disable */ }
    .background-color--neutral-grey-2 a.link-external span, .background-color--neutral-grey-2 a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--neutral-grey-2 a.link-external svg, .background-color--neutral-grey-2 a.link-download svg {
      fill: #ffffff; }

.color--neutral-grey-3 {
  color: #b1b3b3; }
  .color--neutral-grey-3 svg {
    fill: #b1b3b3; }

.stripe-color--neutral-grey-3 {
  background: #b1b3b3;
  color: #ffffff; }

.stripe-headline-color--neutral-grey-3 {
  background: #b1b3b3;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #b1b3b3, -0.25rem 0 0 0.25rem #b1b3b3; }

.background-color--neutral-grey-3 {
  background: #b1b3b3;
  color: #ffffff; }
  .background-color--neutral-grey-3 svg {
    fill: #ffffff; }
  .background-color--neutral-grey-3 a.link-intern, .background-color--neutral-grey-3 a.link-external, .background-color--neutral-grey-3 a.link-download, .background-color--neutral-grey-3 a.link-email {
    color: #ffffff; }
    .background-color--neutral-grey-3 a.link-intern:hover, .background-color--neutral-grey-3 a.link-external:hover, .background-color--neutral-grey-3 a.link-download:hover, .background-color--neutral-grey-3 a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--neutral-grey-3 a.link-intern:hover, .background-color--neutral-grey-3 a.link-external:hover, .background-color--neutral-grey-3 a.link-download:hover, .background-color--neutral-grey-3 a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--neutral-grey-3 a.link-external, .background-color--neutral-grey-3 a.link-download {
    /* stylelint-disable */ }
    .background-color--neutral-grey-3 a.link-external span, .background-color--neutral-grey-3 a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--neutral-grey-3 a.link-external svg, .background-color--neutral-grey-3 a.link-download svg {
      fill: #ffffff; }

.color--neutral-grey-4 {
  color: #97999b; }
  .color--neutral-grey-4 svg {
    fill: #97999b; }

.stripe-color--neutral-grey-4 {
  background: #97999b;
  color: #ffffff; }

.stripe-headline-color--neutral-grey-4 {
  background: #97999b;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #97999b, -0.25rem 0 0 0.25rem #97999b; }

.background-color--neutral-grey-4 {
  background: #97999b;
  color: #ffffff; }
  .background-color--neutral-grey-4 svg {
    fill: #ffffff; }
  .background-color--neutral-grey-4 a.link-intern, .background-color--neutral-grey-4 a.link-external, .background-color--neutral-grey-4 a.link-download, .background-color--neutral-grey-4 a.link-email {
    color: #ffffff; }
    .background-color--neutral-grey-4 a.link-intern:hover, .background-color--neutral-grey-4 a.link-external:hover, .background-color--neutral-grey-4 a.link-download:hover, .background-color--neutral-grey-4 a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--neutral-grey-4 a.link-intern:hover, .background-color--neutral-grey-4 a.link-external:hover, .background-color--neutral-grey-4 a.link-download:hover, .background-color--neutral-grey-4 a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--neutral-grey-4 a.link-external, .background-color--neutral-grey-4 a.link-download {
    /* stylelint-disable */ }
    .background-color--neutral-grey-4 a.link-external span, .background-color--neutral-grey-4 a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--neutral-grey-4 a.link-external svg, .background-color--neutral-grey-4 a.link-download svg {
      fill: #ffffff; }

.color--neutral-grey-5 {
  color: #75787b; }
  .color--neutral-grey-5 svg {
    fill: #75787b; }

.stripe-color--neutral-grey-5 {
  background: #75787b;
  color: #ffffff; }

.stripe-headline-color--neutral-grey-5 {
  background: #75787b;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #75787b, -0.25rem 0 0 0.25rem #75787b; }

.background-color--neutral-grey-5 {
  background: #75787b;
  color: #ffffff; }
  .background-color--neutral-grey-5 svg {
    fill: #ffffff; }
  .background-color--neutral-grey-5 a.link-intern, .background-color--neutral-grey-5 a.link-external, .background-color--neutral-grey-5 a.link-download, .background-color--neutral-grey-5 a.link-email {
    color: #ffffff; }
    .background-color--neutral-grey-5 a.link-intern:hover, .background-color--neutral-grey-5 a.link-external:hover, .background-color--neutral-grey-5 a.link-download:hover, .background-color--neutral-grey-5 a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--neutral-grey-5 a.link-intern:hover, .background-color--neutral-grey-5 a.link-external:hover, .background-color--neutral-grey-5 a.link-download:hover, .background-color--neutral-grey-5 a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--neutral-grey-5 a.link-external, .background-color--neutral-grey-5 a.link-download {
    /* stylelint-disable */ }
    .background-color--neutral-grey-5 a.link-external span, .background-color--neutral-grey-5 a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--neutral-grey-5 a.link-external svg, .background-color--neutral-grey-5 a.link-download svg {
      fill: #ffffff; }

.color--neutral-silver {
  color: #f5f5f5; }
  .color--neutral-silver svg {
    fill: #f5f5f5; }

.stripe-color--neutral-silver {
  background: #f5f5f5;
  color: #ffffff; }

.stripe-headline-color--neutral-silver {
  background: #f5f5f5;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #f5f5f5, -0.25rem 0 0 0.25rem #f5f5f5; }

.background-color--neutral-silver {
  background: #f5f5f5;
  color: #ffffff; }
  .background-color--neutral-silver svg {
    fill: #ffffff; }
  .background-color--neutral-silver a.link-intern, .background-color--neutral-silver a.link-external, .background-color--neutral-silver a.link-download, .background-color--neutral-silver a.link-email {
    color: #ffffff; }
    .background-color--neutral-silver a.link-intern:hover, .background-color--neutral-silver a.link-external:hover, .background-color--neutral-silver a.link-download:hover, .background-color--neutral-silver a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--neutral-silver a.link-intern:hover, .background-color--neutral-silver a.link-external:hover, .background-color--neutral-silver a.link-download:hover, .background-color--neutral-silver a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--neutral-silver a.link-external, .background-color--neutral-silver a.link-download {
    /* stylelint-disable */ }
    .background-color--neutral-silver a.link-external span, .background-color--neutral-silver a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--neutral-silver a.link-external svg, .background-color--neutral-silver a.link-download svg {
      fill: #ffffff; }

.color--border-grey {
  color: #e2e2e1; }
  .color--border-grey svg {
    fill: #e2e2e1; }

.stripe-color--border-grey {
  background: #e2e2e1;
  color: #ffffff; }

.stripe-headline-color--border-grey {
  background: #e2e2e1;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #e2e2e1, -0.25rem 0 0 0.25rem #e2e2e1; }

.background-color--border-grey {
  background: #e2e2e1;
  color: #ffffff; }
  .background-color--border-grey svg {
    fill: #ffffff; }
  .background-color--border-grey a.link-intern, .background-color--border-grey a.link-external, .background-color--border-grey a.link-download, .background-color--border-grey a.link-email {
    color: #ffffff; }
    .background-color--border-grey a.link-intern:hover, .background-color--border-grey a.link-external:hover, .background-color--border-grey a.link-download:hover, .background-color--border-grey a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--border-grey a.link-intern:hover, .background-color--border-grey a.link-external:hover, .background-color--border-grey a.link-download:hover, .background-color--border-grey a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--border-grey a.link-external, .background-color--border-grey a.link-download {
    /* stylelint-disable */ }
    .background-color--border-grey a.link-external span, .background-color--border-grey a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--border-grey a.link-external svg, .background-color--border-grey a.link-download svg {
      fill: #ffffff; }

.color--footer-grey {
  color: #526475; }
  .color--footer-grey svg {
    fill: #526475; }

.stripe-color--footer-grey {
  background: #526475;
  color: #ffffff; }

.stripe-headline-color--footer-grey {
  background: #526475;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #526475, -0.25rem 0 0 0.25rem #526475; }

.background-color--footer-grey {
  background: #526475;
  color: #ffffff; }
  .background-color--footer-grey svg {
    fill: #ffffff; }
  .background-color--footer-grey a.link-intern, .background-color--footer-grey a.link-external, .background-color--footer-grey a.link-download, .background-color--footer-grey a.link-email {
    color: #ffffff; }
    .background-color--footer-grey a.link-intern:hover, .background-color--footer-grey a.link-external:hover, .background-color--footer-grey a.link-download:hover, .background-color--footer-grey a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--footer-grey a.link-intern:hover, .background-color--footer-grey a.link-external:hover, .background-color--footer-grey a.link-download:hover, .background-color--footer-grey a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--footer-grey a.link-external, .background-color--footer-grey a.link-download {
    /* stylelint-disable */ }
    .background-color--footer-grey a.link-external span, .background-color--footer-grey a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--footer-grey a.link-external svg, .background-color--footer-grey a.link-download svg {
      fill: #ffffff; }

.color--footer-grey-dark {
  color: #323d48; }
  .color--footer-grey-dark svg {
    fill: #323d48; }

.stripe-color--footer-grey-dark {
  background: #323d48;
  color: #ffffff; }

.stripe-headline-color--footer-grey-dark {
  background: #323d48;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #323d48, -0.25rem 0 0 0.25rem #323d48; }

.background-color--footer-grey-dark {
  background: #323d48;
  color: #ffffff; }
  .background-color--footer-grey-dark svg {
    fill: #ffffff; }
  .background-color--footer-grey-dark a.link-intern, .background-color--footer-grey-dark a.link-external, .background-color--footer-grey-dark a.link-download, .background-color--footer-grey-dark a.link-email {
    color: #ffffff; }
    .background-color--footer-grey-dark a.link-intern:hover, .background-color--footer-grey-dark a.link-external:hover, .background-color--footer-grey-dark a.link-download:hover, .background-color--footer-grey-dark a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--footer-grey-dark a.link-intern:hover, .background-color--footer-grey-dark a.link-external:hover, .background-color--footer-grey-dark a.link-download:hover, .background-color--footer-grey-dark a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--footer-grey-dark a.link-external, .background-color--footer-grey-dark a.link-download {
    /* stylelint-disable */ }
    .background-color--footer-grey-dark a.link-external span, .background-color--footer-grey-dark a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--footer-grey-dark a.link-external svg, .background-color--footer-grey-dark a.link-download svg {
      fill: #ffffff; }

.color--main-grey {
  color: #323d48; }
  .color--main-grey svg {
    fill: #323d48; }

.stripe-color--main-grey {
  background: #323d48;
  color: #ffffff; }

.stripe-headline-color--main-grey {
  background: #323d48;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #323d48, -0.25rem 0 0 0.25rem #323d48; }

.background-color--main-grey {
  background: #323d48;
  color: #ffffff; }
  .background-color--main-grey svg {
    fill: #ffffff; }
  .background-color--main-grey a.link-intern, .background-color--main-grey a.link-external, .background-color--main-grey a.link-download, .background-color--main-grey a.link-email {
    color: #ffffff; }
    .background-color--main-grey a.link-intern:hover, .background-color--main-grey a.link-external:hover, .background-color--main-grey a.link-download:hover, .background-color--main-grey a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--main-grey a.link-intern:hover, .background-color--main-grey a.link-external:hover, .background-color--main-grey a.link-download:hover, .background-color--main-grey a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--main-grey a.link-external, .background-color--main-grey a.link-download {
    /* stylelint-disable */ }
    .background-color--main-grey a.link-external span, .background-color--main-grey a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--main-grey a.link-external svg, .background-color--main-grey a.link-download svg {
      fill: #ffffff; }

.color--warm-white {
  color: #f3f2f3; }
  .color--warm-white svg {
    fill: #f3f2f3; }

.stripe-color--warm-white {
  background: #f3f2f3;
  color: #ffffff; }

.stripe-headline-color--warm-white {
  background: #f3f2f3;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #f3f2f3, -0.25rem 0 0 0.25rem #f3f2f3; }

.background-color--warm-white {
  background: #f3f2f3;
  color: #ffffff; }
  .background-color--warm-white svg {
    fill: #ffffff; }
  .background-color--warm-white a.link-intern, .background-color--warm-white a.link-external, .background-color--warm-white a.link-download, .background-color--warm-white a.link-email {
    color: #ffffff; }
    .background-color--warm-white a.link-intern:hover, .background-color--warm-white a.link-external:hover, .background-color--warm-white a.link-download:hover, .background-color--warm-white a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--warm-white a.link-intern:hover, .background-color--warm-white a.link-external:hover, .background-color--warm-white a.link-download:hover, .background-color--warm-white a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--warm-white a.link-external, .background-color--warm-white a.link-download {
    /* stylelint-disable */ }
    .background-color--warm-white a.link-external span, .background-color--warm-white a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--warm-white a.link-external svg, .background-color--warm-white a.link-download svg {
      fill: #ffffff; }

.color--red {
  color: #e40046; }
  .color--red svg {
    fill: #e40046; }

.stripe-color--red {
  background: #e40046;
  color: #ffffff; }

.stripe-headline-color--red {
  background: #e40046;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #e40046, -0.25rem 0 0 0.25rem #e40046; }

.background-color--red {
  background: #e40046;
  color: #ffffff; }
  .background-color--red svg {
    fill: #ffffff; }
  .background-color--red a.link-intern, .background-color--red a.link-external, .background-color--red a.link-download, .background-color--red a.link-email {
    color: #ffffff; }
    .background-color--red a.link-intern:hover, .background-color--red a.link-external:hover, .background-color--red a.link-download:hover, .background-color--red a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--red a.link-intern:hover, .background-color--red a.link-external:hover, .background-color--red a.link-download:hover, .background-color--red a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--red a.link-external, .background-color--red a.link-download {
    /* stylelint-disable */ }
    .background-color--red a.link-external span, .background-color--red a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--red a.link-external svg, .background-color--red a.link-download svg {
      fill: #ffffff; }

.color--teaser-box-grey {
  color: #f3f2f3; }
  .color--teaser-box-grey svg {
    fill: #f3f2f3; }

.stripe-color--teaser-box-grey {
  background: #f3f2f3;
  color: #ffffff; }

.stripe-headline-color--teaser-box-grey {
  background: #f3f2f3;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #f3f2f3, -0.25rem 0 0 0.25rem #f3f2f3; }

.background-color--teaser-box-grey {
  background: #f3f2f3;
  color: #ffffff; }
  .background-color--teaser-box-grey svg {
    fill: #ffffff; }
  .background-color--teaser-box-grey a.link-intern, .background-color--teaser-box-grey a.link-external, .background-color--teaser-box-grey a.link-download, .background-color--teaser-box-grey a.link-email {
    color: #ffffff; }
    .background-color--teaser-box-grey a.link-intern:hover, .background-color--teaser-box-grey a.link-external:hover, .background-color--teaser-box-grey a.link-download:hover, .background-color--teaser-box-grey a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--teaser-box-grey a.link-intern:hover, .background-color--teaser-box-grey a.link-external:hover, .background-color--teaser-box-grey a.link-download:hover, .background-color--teaser-box-grey a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--teaser-box-grey a.link-external, .background-color--teaser-box-grey a.link-download {
    /* stylelint-disable */ }
    .background-color--teaser-box-grey a.link-external span, .background-color--teaser-box-grey a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--teaser-box-grey a.link-external svg, .background-color--teaser-box-grey a.link-download svg {
      fill: #ffffff; }

.color--soft-violett {
  color: #d6c8e0; }
  .color--soft-violett svg {
    fill: #d6c8e0; }

.stripe-color--soft-violett {
  background: #d6c8e0;
  color: #ffffff; }

.stripe-headline-color--soft-violett {
  background: #d6c8e0;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #d6c8e0, -0.25rem 0 0 0.25rem #d6c8e0; }

.background-color--soft-violett {
  background: #d6c8e0;
  color: #ffffff; }
  .background-color--soft-violett svg {
    fill: #ffffff; }
  .background-color--soft-violett a.link-intern, .background-color--soft-violett a.link-external, .background-color--soft-violett a.link-download, .background-color--soft-violett a.link-email {
    color: #ffffff; }
    .background-color--soft-violett a.link-intern:hover, .background-color--soft-violett a.link-external:hover, .background-color--soft-violett a.link-download:hover, .background-color--soft-violett a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--soft-violett a.link-intern:hover, .background-color--soft-violett a.link-external:hover, .background-color--soft-violett a.link-download:hover, .background-color--soft-violett a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--soft-violett a.link-external, .background-color--soft-violett a.link-download {
    /* stylelint-disable */ }
    .background-color--soft-violett a.link-external span, .background-color--soft-violett a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--soft-violett a.link-external svg, .background-color--soft-violett a.link-download svg {
      fill: #ffffff; }

.color--soft-cyan {
  color: #bfeaf7; }
  .color--soft-cyan svg {
    fill: #bfeaf7; }

.stripe-color--soft-cyan {
  background: #bfeaf7;
  color: #ffffff; }

.stripe-headline-color--soft-cyan {
  background: #bfeaf7;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #bfeaf7, -0.25rem 0 0 0.25rem #bfeaf7; }

.background-color--soft-cyan {
  background: #bfeaf7;
  color: #ffffff; }
  .background-color--soft-cyan svg {
    fill: #ffffff; }
  .background-color--soft-cyan a.link-intern, .background-color--soft-cyan a.link-external, .background-color--soft-cyan a.link-download, .background-color--soft-cyan a.link-email {
    color: #ffffff; }
    .background-color--soft-cyan a.link-intern:hover, .background-color--soft-cyan a.link-external:hover, .background-color--soft-cyan a.link-download:hover, .background-color--soft-cyan a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--soft-cyan a.link-intern:hover, .background-color--soft-cyan a.link-external:hover, .background-color--soft-cyan a.link-download:hover, .background-color--soft-cyan a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--soft-cyan a.link-external, .background-color--soft-cyan a.link-download {
    /* stylelint-disable */ }
    .background-color--soft-cyan a.link-external span, .background-color--soft-cyan a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--soft-cyan a.link-external svg, .background-color--soft-cyan a.link-download svg {
      fill: #ffffff; }

.color--soft-yellow {
  color: #ffe673; }
  .color--soft-yellow svg {
    fill: #ffe673; }

.stripe-color--soft-yellow {
  background: #ffe673;
  color: #ffffff; }

.stripe-headline-color--soft-yellow {
  background: #ffe673;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #ffe673, -0.25rem 0 0 0.25rem #ffe673; }

.background-color--soft-yellow {
  background: #ffe673;
  color: #ffffff; }
  .background-color--soft-yellow svg {
    fill: #ffffff; }
  .background-color--soft-yellow a.link-intern, .background-color--soft-yellow a.link-external, .background-color--soft-yellow a.link-download, .background-color--soft-yellow a.link-email {
    color: #ffffff; }
    .background-color--soft-yellow a.link-intern:hover, .background-color--soft-yellow a.link-external:hover, .background-color--soft-yellow a.link-download:hover, .background-color--soft-yellow a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--soft-yellow a.link-intern:hover, .background-color--soft-yellow a.link-external:hover, .background-color--soft-yellow a.link-download:hover, .background-color--soft-yellow a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--soft-yellow a.link-external, .background-color--soft-yellow a.link-download {
    /* stylelint-disable */ }
    .background-color--soft-yellow a.link-external span, .background-color--soft-yellow a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--soft-yellow a.link-external svg, .background-color--soft-yellow a.link-download svg {
      fill: #ffffff; }

.color--soft-orange {
  color: #ffca82; }
  .color--soft-orange svg {
    fill: #ffca82; }

.stripe-color--soft-orange {
  background: #ffca82;
  color: #ffffff; }

.stripe-headline-color--soft-orange {
  background: #ffca82;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #ffca82, -0.25rem 0 0 0.25rem #ffca82; }

.background-color--soft-orange {
  background: #ffca82;
  color: #ffffff; }
  .background-color--soft-orange svg {
    fill: #ffffff; }
  .background-color--soft-orange a.link-intern, .background-color--soft-orange a.link-external, .background-color--soft-orange a.link-download, .background-color--soft-orange a.link-email {
    color: #ffffff; }
    .background-color--soft-orange a.link-intern:hover, .background-color--soft-orange a.link-external:hover, .background-color--soft-orange a.link-download:hover, .background-color--soft-orange a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--soft-orange a.link-intern:hover, .background-color--soft-orange a.link-external:hover, .background-color--soft-orange a.link-download:hover, .background-color--soft-orange a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--soft-orange a.link-external, .background-color--soft-orange a.link-download {
    /* stylelint-disable */ }
    .background-color--soft-orange a.link-external span, .background-color--soft-orange a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--soft-orange a.link-external svg, .background-color--soft-orange a.link-download svg {
      fill: #ffffff; }

.color--soft-petrol {
  color: #bfebe5; }
  .color--soft-petrol svg {
    fill: #bfebe5; }

.stripe-color--soft-petrol {
  background: #bfebe5;
  color: #ffffff; }

.stripe-headline-color--soft-petrol {
  background: #bfebe5;
  color: #ffffff;
  box-shadow: 0.25rem 0 0 0.25rem #bfebe5, -0.25rem 0 0 0.25rem #bfebe5; }

.background-color--soft-petrol {
  background: #bfebe5;
  color: #ffffff; }
  .background-color--soft-petrol svg {
    fill: #ffffff; }
  .background-color--soft-petrol a.link-intern, .background-color--soft-petrol a.link-external, .background-color--soft-petrol a.link-download, .background-color--soft-petrol a.link-email {
    color: #ffffff; }
    .background-color--soft-petrol a.link-intern:hover, .background-color--soft-petrol a.link-external:hover, .background-color--soft-petrol a.link-download:hover, .background-color--soft-petrol a.link-email:hover {
      text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff; }

@-moz-document url-prefix() {
  .background-color--soft-petrol a.link-intern:hover, .background-color--soft-petrol a.link-external:hover, .background-color--soft-petrol a.link-download:hover, .background-color--soft-petrol a.link-email:hover {
    text-shadow: 0 0 0.3px #ffffff, 0 0 0.3px #ffffff; } }
  .background-color--soft-petrol a.link-external, .background-color--soft-petrol a.link-download {
    /* stylelint-disable */ }
    .background-color--soft-petrol a.link-external span, .background-color--soft-petrol a.link-download span {
      color: #ffffff;
      text-decoration: underline; }
    .background-color--soft-petrol a.link-external svg, .background-color--soft-petrol a.link-download svg {
      fill: #ffffff; }

.background-color--white {
  background: #ffffff;
  color: #9e007e;
  box-shadow: 0 0 0.625rem 0 #d9d9d6; }
  .background-color--white svg {
    fill: #9e007e; }
  .background-color--white a.link-intern {
    color: #9e007e;
    text-decoration: underline; }
    .background-color--white a.link-intern:hover, .background-color--white a.link-intern:focus, .background-color--white a.link-intern:active {
      color: #9e007e;
      text-shadow: 0 0 0.65px #9e007e, 0 0 0.65px #9e007e; }

@-moz-document url-prefix() {
  .background-color--white a.link-intern:hover, .background-color--white a.link-intern:focus, .background-color--white a.link-intern:active {
    text-shadow: 0 0 0.3px #9e007e, 0 0 0.3px #9e007e; } }
  .background-color--white a.link-external, .background-color--white a.link-download {
    color: #9e007e; }
    .background-color--white a.link-external span, .background-color--white a.link-download span {
      color: #9e007e;
      text-decoration: underline; }
    .background-color--white a.link-external svg, .background-color--white a.link-download svg {
      fill: #9e007e; }

@media (max-width: 61.938rem) {
  .mobile-home-top .nav-logo-mobile {
    width: 11.875rem;
    height: auto; } }

.navbar__container--logo-large .nav-logo {
  width: auto;
  height: 4.5rem; }

.navbar__nav-item__icon-wrapper--logo-small {
  -ms-flex-pack: center;
      justify-content: center; }

.navbar__nav-item--logo-small .nav-logo-small {
  width: auto;
  height: 3rem;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }
